<template>
  <div class="container">
    <section class="board">
      <!-- 数据展示 -->
      <div class="header">
        <div class="left" @click="goList(data.totalList)">
          <h1>{{ data.totalList&&data.totalList.length }}</h1>
          <p>人员总数</p>
        </div>
        <div class="right" @click="$router.push({ name: 'Switch' })">
          <span>{{ data.mainName }}</span>
          <i class="el-icon-sort" style="transform: rotateZ(90deg)"></i>
        </div>
      </div>
      <ul class="row">
        <li @click="goList(data.inList)">
          <h5>{{ data.inList&&data.inList.length }}</h5>
          <p>境内人员</p>
        </li>
        <li @click="goList(data.inOwnList)">
          <h5>{{ data.inOwnList&&data.inOwnList.length+ (data.inExternalList&&data.inExternalList.length)  }}</h5>
          <p>管理人员</p>
        </li>
        <!-- <li @click="goList(data.inExternalList)">
          <h5>{{ data.inExternalList&&data.inExternalList.length }}</h5>
          <p>外聘人员</p>
        </li> -->
        <li @click="goList(data.inServiceList)">
          <h5>{{ data.inServiceList&&data.inServiceList.length }}</h5>
          <p>务工人员</p>
        </li>
      </ul>
      <ul class="row">
        <li  @click="goList(data.outList)">
          <h5>{{ data.outList&&data.outList.length }}</h5>
          <p>境外人员</p>
        </li>
        <li @click="goList(data.outOwnList)">
          <h5>{{ data.outOwnList&&data.outOwnList.length + (data.outExternalList&&data.outExternalList.length) }}</h5>
          <p>管理人员</p>
        </li>
        <!-- <li @click="goList(data.outExternalList)">
          <h5>{{ data.outExternalList&&data.outExternalList.length }}</h5>
          <p>外聘人员</p>
        </li> -->
        <li @click="goList(data.outServiceList)">
          <h5>{{ data.outServiceList&&data.outServiceList.length }}</h5>
          <p>务工人员</p>
        </li>
      </ul>
      <!-- 数据板 -->
    </section>
    <section>
      <!-- 1 -->
      <div class="part" >
        <h1 @click="goList(data.inList)">境内人员 {{ data.inList&&data.inList.length }}</h1>
        <div class="content">
          <div class="right">
            <div class="number" @click="goList(data.inIsolateMedicalList)">
              <span>{{ data.inIsolateMedicalList&&data.inIsolateMedicalList.length }}</span>
              <p>医学隔离</p>
            </div>
            <div class="number" @click="goList(data.inIsolateHomeList)">
              <span>{{ data.inIsolateHomeList&&data.inIsolateHomeList.length }}</span>
              <p>居家隔离</p>
            </div>
            <div class="number" @click="goList(data.inIsolateCenterList)">
              <span>{{ data.inIsolateCenterList&&data.inIsolateCenterList.length }}</span>
              <p>集中隔离</p>
            </div>
            <div class="number" @click="goList(data.inIsolateWatchList)">
              <span>{{ data.inIsolateWatchList&&data.inIsolateWatchList.length }}</span>
              <p>健康监测</p>
            </div>
            <div class="number" @click="goList(data.inIsolateSubCloseList)">
              <span>{{ data.inIsolateSubCloseList&&data.inIsolateSubCloseList.length }}</span>
              <p>次密切接触</p>
            </div>
            <div class="number" @click="goList(data.inIsolateCloseList)">
              <span>{{ data.inIsolateCloseList&&data.inIsolateCloseList.length }}</span>
              <p>密切接触</p>
            </div>
            <div class="number" @click="goList(data.inPositiveList)">
              <span>{{ data.inPositiveList&&data.inPositiveList.length }}</span>
              <p>核酸阳性</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 境外人员 -->
      <section class="part-content">
        <!-- 1 -->
        <div class="part" >
          <h1 @click="goList(data.outList)">境外人员 {{ data.outList&&data.outList.length }}</h1>
          <div class="content">
            <div class="left" @click="goList(data.outNowList)">
              <h5>{{ data.outNowList&&data.outNowList.length }}</h5>
              <p>现驻人员</p>
            </div>
            <div class="right">
              <div class="number" @click="goList(data.outNowIsolateMedicalList)">
                <span>{{ data.outNowIsolateMedicalList&&data.outNowIsolateMedicalList.length }}</span>
                <p>医学隔离</p>
              </div>
              <div class="number"  @click="goList(data.outNowIsolateHomeList)">
                <span>{{ data.outNowIsolateHomeList&&data.outNowIsolateHomeList.length }}</span>
                <p>居家隔离</p>
              </div>
              <div class="number"  @click="goList(data.outNowIsolateCenterList)">
                <span>{{ data.outNowIsolateCenterList&&data.outNowIsolateCenterList.length }}</span>
                <p>集中隔离</p>
              </div>
              <div class="number" @click="goList(data.outNowIsolateWatchList)">
                <span>{{ data.outNowIsolateWatchList&&data.outNowIsolateWatchList.length }}</span>
                <p>健康观察</p>
              </div>
              <div class="number color1"  @click="goList(data.outNowIsolateSubCloseList)">
                <span>{{ data.outNowIsolateSubCloseList&&data.outNowIsolateSubCloseList.length }}</span>
                <p>次密切接触</p>
              </div>
              <div class="number color2" @click="goList(data.outNowIsolateCloseList)">
                <span>{{ data.outNowIsolateCloseList&&data.outNowIsolateCloseList.length }}</span>
                <p>密切接触</p>
              </div>
              <div class="number color2" @click="goList(data.outNowPositiveList)">
                <span>{{ data.outNowPositiveList&&data.outNowPositiveList.length }}</span>
                <p>核酸阳性</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 2 -->
        <div class="part" >
          <!-- <h1>国内隔离情况</h1> -->
          <div class="content">
            <div class="left">
              <h5 @click="goList(data.outReturnList)">{{ data.outReturnList&&data.outReturnList.length }}</h5>
              <p>回国人数</p>
            </div>
            <div class="right">
              <div class="number" @click="goList(data.outReturnIsolateMedicalList)">
                <span>{{ data.outReturnIsolateMedicalList&&data.outReturnIsolateMedicalList.length }}</span>
                <p>医学隔离</p>
              </div>
              <div class="number" @click="goList(data.outReturnIsolateHomeList)">
                <span>{{ data.outReturnIsolateHomeList&&data.outReturnIsolateHomeList.length }}</span>
                <p>居家隔离</p>
              </div>
              <div class="number" @click="goList(data.outReturnIsolateCenterList)">
                <span>{{ data.outReturnIsolateCenterList&&data.outReturnIsolateCenterList.length }}</span>
                <p>集中隔离</p>
              </div>
              <div class="number" @click="goList(data.outReturnIsolateWatchList)">
                <span>{{ data.outReturnIsolateWatchList&&data.outReturnIsolateWatchList.length }}</span>
                <p>健康观察</p>
              </div>
              <div class="number color1" @click="goList(data.outReturnIsolateSubCloseList)">
                <span>{{ data.outReturnIsolateSubCloseList&&data.outReturnIsolateSubCloseList.length }}</span>
                <p>次密切接触</p>
              </div>
              <div class="number color2" @click="goList(data.outReturnIsolateCloseList)">
                <span>{{ data.outReturnIsolateCloseList&&data.outReturnIsolateCloseList.length }}</span>
                <p>密切接触</p>
              </div>
              <div class="number color2 "  @click="goList(data.outReturnPositiveList)">
                <span>{{ data.outReturnPositiveList&&data.outReturnPositiveList.length }}</span>
                <p>核酸阳性</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 3 -->
        <div class="part" >
          <!-- <h1>境外隔离情况</h1> -->
          <div class="content">
            <div class="left">
              <h5 @click="goList(data.outNotGoList)">{{ data.outNotGoList&&data.outNotGoList.length }}</h5>
              <p>未出国人员</p>
            </div>
            <div class="right">
              <div class="number"  @click="goList(data.outReturnIsolateMedicalList)">
                <span>{{ data.outNotGoIsolateMedicalList&&data.outNotGoIsolateMedicalList.length }}</span>
                <p>医学隔离</p>
              </div>
              <div class="number" @click="goList(data.outNotGoIsolateHomeList)">
                <span>{{ data.outNotGoIsolateHomeList&&data.outNotGoIsolateHomeList.length }}</span>
                <p>居家隔离</p>
              </div>
              <div class="number"  @click="goList(data.outNotGoIsolateCenterList)">
                <span>{{ data.outNotGoIsolateCenterList&&data.outNotGoIsolateCenterList.length }}</span>
                <p>集中隔离</p>
              </div>
              <div class="number" @click="goList(data.outNotGoIsolateWatchList)">
                <span>{{ data.outNotGoIsolateWatchList&&data.outNotGoIsolateWatchList.length }}</span>
                <p>健康观察</p>
              </div>
              <div class="number color1" @click="goList(data.outNotGoIsolateSubCloseList)">
                <span>{{ data.outNotGoIsolateSubCloseList&&data.outNotGoIsolateSubCloseList.length }}</span>
                <p>次密切接触</p>
              </div>
              <div class="number color2" @click="goList(data.outNotGoIsolateCloseList)">
                <span>{{ data.outNotGoIsolateCloseList&&data.outNotGoIsolateCloseList.length }}</span>
                <p>密切接触</p>
              </div>
              <div class="number color2"  @click="goList(data.outNotGoPositiveList)">
                <span>{{ data.outNotGoPositiveList&&data.outNotGoPositiveList.length }}</span>
                <p>核酸阳性</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <!-- 人员列表 -->
    <!-- <section class="tableBox" v-if="t!='project'">
      <h5>机构</h5>
      <ul class="table">
        <li class="thead">
          <span>名称</span>
          <span>人员总数</span>
          <span>在岗</span>
          <span>回国人数</span>
          <span>隔离</span>
        </li>
        <li class="th" v-for="item in data.returnWorkReportList" @click="redirect(item)">
          <span>{{item.mainName}}</span>
          <span>{{item.total}}</span>
          <span>{{item.workNum}}</span>
          <span>{{item.returnNum}}</span>
          <span>{{~~item.inIsolateNum+~~item.outIsolateNum}}</span>
        </li>
      </ul>
    </section> -->
  </div>
</template>

<script>
import { returnWorkData } from "@/api/api";
import {mapGetters} from 'vuex'
export default {
  data() {
    return {
      data: {},
      t: "",
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    type() {
      let t = this.$route.query.type;
      let r = "";
      switch (t) {
        case "organization":
          t = "country";
          break;
        case "country":
          t = "project";
          break;
        case "project":
          t = "";
          break;
        default:
          t = "organization";
          break;
      }
      return t;
    },
    ...mapGetters(['projectForm'])
  },
  methods: {
    init() {
      let params = this.$route.query;
      this.t = params.type;
      if (this.t == "project") {
        document.title = `${params.name}人员情况`;
        this.getData({ project: params.name });
      } else if (this.t == "country") {
        document.title = `${params.name}人员情况`;
        this.getData({ country: params.name });
      } else if (this.t == "organization") {
        document.title = `${params.name}人员情况`;
        this.getData({ institution: params.name });
      } else {
        document.title = `人员情况`;
        this.getData();
      }
    },
    goList(item) {
      this.$router.push({
        name: "ReturnWorkList",
        query: {
          list:JSON.stringify(item)
        },
      });
    },
    async getData(query) {
      let res = await returnWorkData(this.projectForm);
      this.data = res.data.data;
    },
    redirect(item) {
      // this.$router.push({
      //   name: "Redirect",
      //   query: {
      //     name: item.mainName,
      //     type: this.type,
      //   },
      // });
    },
  },
  watch: {
    $route: function () {
      this.init();
    },
  },
};
</script>

<style scoped>
.container {
  padding-bottom: 0.3rem;
}
.board {
  background: #2f7dcd;
  padding: 0.2rem 0.15rem;
}
.databoard {
  display: flex;
}
.databoard > div {
  flex: 1;
  text-align: center;
  color: #ffffff;
  padding: 0.1rem 0 0.2rem;
}
.databoard > div > h5 {
  font-size: 0.3rem;
  font-weight: normal;
}
.databoard > div > p {
  font-size: 0.13rem;
}
.part {
  background: #fff;
  margin-bottom: 0.15rem;
  border-radius: 10px;
}

/* .part:first-child h1 {
  background: rgba(97, 133, 178, 0.24);
}
.part:first-child .content .left {
  color: #666;
} */
.content {
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
}
.content .left {
  color: #ec5d78;
  text-align: center;
  flex: 1;
  border-right: 1px solid #707070;
}
.content .right {
  flex: 2.9;
  padding: 0 0.1rem;
}
.content .left h5 {
  font-weight: normal;
  font-size: 0.35rem;
  line-height: 1.3;
}
.content .left p {
  font-size: 0.12rem;
}
.content .right {
  display: flex;
  /* justify-content: space-around; */
  flex-wrap: wrap;
}
.content .right .number {
  text-align: center;
  color: #666;
  width: 25%;
  margin: 0.05rem 0;
}
.content .right .number span {
  font-size: 0.24rem;
}
.content .right .number p {
  font-size: 0.12rem;
}
.color1 span {
  color: #f7b51b;
}
.color2 span {
  color: #ec5d78;
}
.tableBox {
  padding: 0.1rem;
}
.tableBox h5 {
  color: #999999;
  font-size: 0.16rem;
  margin-bottom: 0.1rem;
}
.table {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
.table li {
  display: flex;
  justify-content: space-between;
  font-size: 0.13rem;
  color: #707071;
  line-height: 0.35rem;
  border-bottom: 1px solid rgba(112, 112, 112, 0.19);
}
.table li span {
  flex: 1;
  text-align: center;
}
.table li.thead {
  background: rgba(79, 119, 170, 0.24);
  line-height: 0.4rem;
}

/* new  */
.header {
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
}
.header .left {
  text-align: left;
}
.header .left h1 {
  font-size: 0.35rem;
  line-height: 1.2;
  font-weight: normal;
}
.header .left p {
  font-weight: lighter;
  font-size: 0.12rem;
}
.header .right {
  font-size: 0.16rem;
  font-weight: normal;
}
.header .right span {
  margin-right: 5px;
}
.row {
  display: flex;
  justify-content: space-between;
  list-style: none;
  color: #fff;
  margin: 0.15rem 0 0.3rem;
  text-align: center;
}
.row h5 {
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 1.2;
}
.row p {
  font-size: 0.12rem;
  font-weight: lighter;
}
.row li {
  flex: 1;
}
.row li:first-child {
  border-right: 1px dotted #fff;
  text-align: left;
  flex: 0.9;
}
.part h1 {
  font-weight: bold;
  font-size: 0.15rem;
  line-height: 0.4rem;
  padding: 0 0.15rem;
  background: #f5f5f5;
  color: #333;
}
.part-content {
  background: #fff;
  padding-bottom: 0.15rem;
}
.part-content .content {
  background: rgba(59, 115, 198, 0.05);
  margin: 0.15rem;
  border-radius: 0.1rem;
}
</style>